import { zonedTimeToUtc, toDate, utcToZonedTime } from 'date-fns-tz';

import { TIME_ZONE } from '@common/constants/dates';

export function formatDate(date, language) {
  const expiredFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  return date.toLocaleString(language, expiredFormatOptions);
}

export function formatDayAndTime(date, locale) {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    weekday: 'long',
  };

  return new Intl.DateTimeFormat(locale, options).format(date);
}

export function formatTime(date, locale) {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
  };

  return new Intl.DateTimeFormat(locale, options).format(date);
}

export function forceTwoDigits(digit) {
  return digit < 9 ? `0${digit}` : digit;
}

export const convertZurichTzToUtc = date => zonedTimeToUtc(date, TIME_ZONE);

export const convertUtcToZurichTz = date => utcToZonedTime(date, TIME_ZONE);

export const convertToZurichTz = date => toDate(date, { timeZone: TIME_ZONE });

export const getDateZurich = () =>
  convertUtcToZurichTz(new Date().toISOString());
