import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './IconButton.styled';

export const IconButton = ({
  onClick,
  className,
  icon,
  'data-testid': dataTestid,
  customAs,
  ...props
}) => (
  <StyledButton
    data-testid={dataTestid}
    onClick={onClick}
    className={className}
    as={customAs}
    {...props}
  >
    {icon}
  </StyledButton>
);

IconButton.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  onClick: undefined,
  customAs: undefined,
};

IconButton.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  customAs: PropTypes.string,
};

export default IconButton;
