import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import endOfDay from 'date-fns/endOfDay';
import { useRouter } from 'next/router';

import { getRouteUrl } from '@utils/urls';
import { convertToZurichTz, getDateZurich } from '@utils/date';
import { numberToSup } from '@utils/formatNumber';
import { SQUARE } from '@utils/image';

import OfferPill from '@common/components/OfferPill';
import Heart from '@common/components/Heart';
import PriceText from '@common/components/PriceText';
import { OFFER_TYPES } from '@common/constants/filters';
import { PRODUCT } from '@common/routes';
import {
  PriceSmall,
  Title,
  PriceCrossed,
} from '@common/components/ProductComponents';

import {
  FavoriteButton,
  ImageWrapper,
  Price,
  ProductBottom,
  ProductLink,
  ProductImage,
  ProductTop,
  Wrapper,
  OfferPillWrapper,
} from './ProductBox.styled';
import OutdatedMask from './OutdatedMask';

export function ProductBox({
  image,
  isFavorited,
  offerType,
  price,
  productColorId,
  publishedUntil,
  slug,
  specialPrice,
  title,
  toggleFavorite,
  innerRef,
  onProductLinkClick,
  imageWidths,
}) {
  const [isFavoriteToggle, setIsFavoriteToggle] = useState(false);
  const { t } = useTranslation();
  const { locale, events: routerEvents } = useRouter();
  const currentPrice =
    offerType !== null && specialPrice ? specialPrice : price;
  const isSale = offerType === OFFER_TYPES.SALE;
  const isOffer =
    offerType === OFFER_TYPES.OFFER || offerType === OFFER_TYPES.VIPOFFER;
  const isOutdated = useMemo(
    () =>
      publishedUntil &&
      getDateZurich() > convertToZurichTz(endOfDay(new Date(publishedUntil))),
    [publishedUntil],
  );

  const href = {
    pathname: PRODUCT,
    query: {
      id: productColorId,
      slug,
    },
  };
  const hrefAs = getRouteUrl(locale, PRODUCT, {
    id: productColorId,
    slug,
  });

  useEffect(() => {
    setIsFavoriteToggle(false);
    const onRouteChangeComplete = () => {
      setIsFavoriteToggle(false);
    };

    routerEvents.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      routerEvents.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [routerEvents]);

  const handleFavoriteClick = async event => {
    setIsFavoriteToggle(true);
    await toggleFavorite(event);
  };

  return (
    <Wrapper ref={innerRef} data-testid="product">
      {isOutdated && <OutdatedMask linkHref={href} linkHrefAs={hrefAs} />}
      <ProductTop>
        <OfferPillWrapper>
          <OfferPill offerType={offerType} />
        </OfferPillWrapper>
        <FavoriteButton
          data-testid="favorite-button"
          onClick={handleFavoriteClick}
          icon={
            <Heart
              data-testid={
                isFavorited ? 'favorite-icon-on' : 'favorite-icon-off'
              }
              $isFavorited={isFavorited}
              $isFavoriteToggle={isFavoriteToggle}
            />
          }
        />
        <ImageWrapper>
          <Link legacyBehavior href={href} as={hrefAs} passHref>
            <ProductLink onClick={onProductLinkClick}>
              <ProductImage
                alt={title}
                src={image}
                widths={
                  imageWidths?.length
                    ? imageWidths
                    : [491, 450, 380, 330, 440, 340, 270, 200, 375]
                }
                aspectRatios={SQUARE}
              />
            </ProductLink>
          </Link>
        </ImageWrapper>
      </ProductTop>
      <Link legacyBehavior href={href} as={hrefAs} passHref>
        <ProductLink onClick={onProductLinkClick}>
          <ProductBottom isOutdated={isOutdated}>
            <Title offerType={offerType}>{title}</Title>
            {isSale && !!price && (
              <PriceCrossed>
                CHF <PriceText>{numberToSup(price)}</PriceText>
              </PriceCrossed>
            )}
            {isOffer && !!price && (
              <PriceSmall offerType={offerType}>
                {t('product.offer.discount', 'statt CHF')}{' '}
                <PriceText>{numberToSup(price)}</PriceText>
              </PriceSmall>
            )}
            {currentPrice && (
              <Price offerType={offerType}>
                CHF <PriceText>{numberToSup(currentPrice)}</PriceText>
              </Price>
            )}
          </ProductBottom>
        </ProductLink>
      </Link>
    </Wrapper>
  );
}

ProductBox.propTypes = {
  image: PropTypes.string.isRequired,
  innerRef: PropTypes.shape({}),
  isFavorited: PropTypes.bool.isRequired,
  offerType: PropTypes.oneOf(Object.keys(OFFER_TYPES)),
  onProductLinkClick: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  productColorId: PropTypes.string.isRequired,
  publishedUntil: PropTypes.string,
  slug: PropTypes.string.isRequired,
  specialPrice: PropTypes.number,
  title: PropTypes.string.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  imageWidths: PropTypes.arrayOf(PropTypes.number),
};

ProductBox.defaultProps = {
  innerRef: undefined,
  offerType: undefined,
  publishedUntil: undefined,
  specialPrice: 0,
  imageWidths: [],
};

export default ProductBox;
