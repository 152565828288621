export const SIZES = { NORMAL: 'normal', SMALL: 'small' };

export const VARIANTS = {
  BRAND: 'brand',
  LIGHT: 'light',
  OUTLINE: 'outline',
  PRIMARY: 'primary',
  QUATERNARY: 'quaternary',
  SECONDARY: 'secondary',
  SHADOW: 'shadow',
  TERTIARY: 'tertiary',
};
