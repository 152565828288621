import styled, { css } from 'styled-components';

import {
  getThemeColor,
  getTypographyStyle,
  media,
  hexToRgba,
} from '@utils/styled';

import { LAYOUT_COLUMN } from '@common/constants/ui';

export const Wrapper = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: ${({ theme }) =>
    hexToRgba(getThemeColor('bluegrey.95')({ theme }), 0.8)};
`;

export const OutdatedLabel = styled.span`
  ${getTypographyStyle('body3')};
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  margin: 0;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 4)}
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 8)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 5)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 8)};
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 5)};
  background-color: ${getThemeColor('hue.red')};
  color: ${getThemeColor('grey.100')};

  ${media.from480down`
    ${getTypographyStyle('body4')};
    ${({ theme }) =>
      theme.layoutPreference === LAYOUT_COLUMN &&
      css`
        ${theme.getFluidSpacing('padding-top', 'equal', 5)};
        ${theme.getFluidSpacing('padding-right', 'widthBased', 0)};
        ${theme.getFluidSpacing('padding-bottom', 'equal', 5)};
        ${theme.getFluidSpacing('padding-left', 'widthBased', 0)};
      `}
  `}
`;
