import styled, { css } from 'styled-components';

import { getGridBase, media } from '@utils/styled';

import { LAYOUT_COLUMN } from '@common/constants/ui';

export const ProductsGrid = styled.div`
  ${getGridBase(12)};
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'widthBased', 1)};
  width: 100%;
`;

function layoutPreferenceToColumns({ theme }) {
  switch (theme.layoutPreference) {
    case 0:
      return 6;
    case 1:
      return 12;
    default:
      return 6;
  }
}

export const ProductWrapper = styled.div`
  position: relative;
  grid-column: span ${layoutPreferenceToColumns};
  ${({ theme }) =>
    theme.layoutPreference === LAYOUT_COLUMN &&
    css`
      ${theme.getFluidSpacing('margin-right', 'widthBased', 0, true)};
      ${theme.getFluidSpacing('margin-left', 'widthBased', 0, true)};
    `};

  ${media.from480up`
    width: 100%;
    grid-column: span 6;
    margin-left: 0;
    margin-right: 0;
  `}

  ${media.from1040up`
    grid-column: span 4;
  `}

  > :first-child {
    height: 100%;
  }
`;
