import PropTypes from 'prop-types';
import React from 'react';

import { SIZES, VARIANTS } from '@common/constants/pills';

import { PillText, StyledPill } from './Pill.styled';

export const Pill = React.forwardRef(
  ({ size, variant, children, ...props }, ref) => (
    <StyledPill size={size} variant={variant} ref={ref} {...props}>
      <PillText pillVariant={variant} size={size}>
        {children}
      </PillText>
    </StyledPill>
  ),
);

Pill.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  variant: PropTypes.oneOf(Object.values(VARIANTS)).isRequired,
};

Pill.defaultProps = {
  size: SIZES.NORMAL,
};

export default Pill;
