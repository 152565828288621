import styled, { css, keyframes } from 'styled-components';

import { getThemeColorWithError } from '@utils/styled';

import { Heart as HeartIcon } from '@common/components/Icons';

const favoriteAnimation = (onColor, offColor) => keyframes`
  0% {
    transform: scale(1);
    fill: ${offColor};
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
    fill: ${onColor};
  }
`;

const Heart = styled(HeartIcon)`
  fill: ${getThemeColorWithError('bluegrey.80', 'hue.green', '$isFavorited')};
  animation: ${({ $isFavorited, theme: { colors } }) =>
    favoriteAnimation(
      $isFavorited
        ? [colors.hue.green, colors.bluegrey[80]]
        : [colors.bluegrey[80], colors.hue.green],
    )};
  ${({ $isFavoriteToggle }) =>
    !$isFavoriteToggle &&
    css`
      animation: none;
    `}
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
`;

export default Heart;
